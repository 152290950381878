<template>
  <div class="main-content-box">
    <bannerEl/>
    <configZone1El/>
  </div>
</template>

<script>
import bannerEl from "@/views/page/racecommon/banner";
import configZone1El from "@/views/page/racecommon/config_zone_1";

export default {
  name: "page_racecommon",
  components:{
    bannerEl,
    configZone1El,
  },
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content-box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
</style>
